import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CategoryNode } from '../../components/home/home.component';

const initialState: State = {
  sideNavCategories: [],
  heading: '',
  isModel: false,
  modelId: undefined,
  isItem: false,
  car: '',
  isCategory: false,
  isModelPage: false,
  vehicleType: 'V',
  selectedDeliveryMethod: null, // Initialize with null
};

export interface CustomerInfo {
  firstName: string;
  lastName: string;
  phone: string;
  street: string;
  postalCode: string;
  city: string;
  billingDifferent: boolean;
  isBusiness: boolean;
}
export interface State {
  sideNavCategories: CategoryNode[];
  heading: string;
  isModel: boolean;
  isItem: boolean;
  modelId?: number;
  vehicleType?: string;
  car: string;
  isCategory: boolean;
  isModelPage: boolean;
  customerInfo?: CustomerInfo;
  selectedDeliveryMethod?: any; // Add this to store delivery option
}

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private stateSource = new BehaviorSubject<State>(initialState);
  currentState = this.stateSource.asObservable();

  // Update general state
  updateState(newState: Partial<State>) {
    const currentState = this.stateSource.value;
    this.stateSource.next({
      ...currentState,
      ...newState,
    });
  }

  // Update customer info specifically
  updateCustomerInfo(customerInfo: Partial<CustomerInfo>) {
    const currentState = this.stateSource.value;
    const defaultCustomerInfo: CustomerInfo = {
      firstName: '',
      lastName: '',
      phone: '',
      street: '',
      postalCode: '',
      city: '',
      billingDifferent: false,
      isBusiness: false,
    };

    // Ensure any undefined properties get default values
    const updatedCustomerInfo: CustomerInfo = {
      ...defaultCustomerInfo,
      ...currentState.customerInfo,
      ...customerInfo, // Customer info passed from the form
    };

    this.stateSource.next({
      ...currentState,
      customerInfo: updatedCustomerInfo,
    });
  }
}
