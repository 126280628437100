<div class="search-banner">
    <h1>Izvēlies savu transportlīdzekli</h1>
    <div class="search-container">
        <div class="dropdown search-field">
            <i [class]="'text-gray-700 fa-solid input-arrow' + (showVehicleTypes ? ' fa-angle-up' : ' fa-angle-down')"
                (click)="showVehicleTypes = !showVehicleTypes"></i>
            <input type="text" [value]="selectedVehicleType.name" readonly
                (click)="showVehicleTypes = !showVehicleTypes" (blur)="hideVehicleTypes()">
            <ul *ngIf="showVehicleTypes && vehicleTypes.length" (mousedown)="$event.preventDefault()">
                <li *ngFor="let type of vehicleTypes" (click)="selectVehicleType(type)">{{type.name}}<i
                        class="text-gray-700 fa-solid fa-angle-right ml-1"></i></li>
            </ul>
        </div>
        <div class="dropdown search-field">
            <i [class]="'text-gray-700 fa-solid input-arrow' + (showBrands ? ' fa-angle-up' : ' fa-angle-down')"
                (click)="filterBrands()"></i>
            <input type="text" [(ngModel)]="searchBrand" (input)="filterBrands()" (focus)="filterBrands()"
                (blur)="hideBrands()" placeholder="Marka" #brandInput>
            <ul *ngIf="showBrands && (favouriteBrands.length || brands.length) && searchBrand == ''"
                (mousedown)="$event.preventDefault()">
                <li class="dropdown-section-title" *ngIf="favouriteBrands.length">Populārākās markas</li>
                <li *ngFor="let brand of favouriteBrands" (click)="selectBrand(brand)">{{brand.manuName}}<i
                        class="text-gray-700 fa-solid fa-angle-right ml-1"></i></li>
                <li class="dropdown-section-title" *ngIf="brands.length">Visas markas </li>
                <li *ngFor="let brand of brands" (click)="selectBrand(brand)">{{brand.manuName}}<i
                        class="text-gray-700 fa-solid fa-angle-right ml-1"></i></li>
            </ul>
            <ul *ngIf="showBrands && filteredBrands.length && searchBrand != ''" (mousedown)="$event.preventDefault()">
                <li *ngFor="let brand of filteredBrands" (click)="selectBrand(brand)">{{brand.manuName}}</li>
            </ul>
        </div>
        <div class="dropdown search-field">
            <i [class]="'text-gray-700 fa-solid input-arrow' + (showModels ? ' fa-angle-up' : ' fa-angle-down')"
                *ngIf="!isModelDisabled" (click)="filterModels()"></i>
            <input type="text" [(ngModel)]="searchModel" (input)="filterModels()" (focus)="filterModels()"
                [disabled]="isModelDisabled" (blur)="hideModels()" placeholder="Modelis" #modelInput>
            <ul *ngIf="showModels && filteredModels.length" (mousedown)="$event.preventDefault()">
                <li *ngFor="let model of filteredModels" (click)="selectModel(model)">
                    <span class="withDate">{{model.name}}
                        <span class="text-sm">{{ " [" +
                            extractYearNumber(model?.beginYearMonth) + (model?.endYearMonth ? "-" : '') +
                            extractYearNumber(model?.endYearMonth) +
                            "]"}}</span>
                    </span><i class="text-gray-700 fa-solid fa-angle-right ml-1"></i>
                </li>
            </ul>
        </div>
        <div class="dropdown search-field">
            <i [class]="'text-gray-700 fa-solid input-arrow' + (showModelTypes ? ' fa-angle-up' : ' fa-angle-down')"
                *ngIf="!isTypeDisabled" (click)="filterTypes()"></i>
            <input type="text" [(ngModel)]="searchType" (input)="filterTypes()" (focus)="filterTypes()"
                (blur)="hideModelTypes()" placeholder="Tips" #typeInput [disabled]="isTypeDisabled">
            <ul *ngIf="showModelTypes && filteredTypes.length" (mousedown)="$event.preventDefault()">
                <li *ngFor="let type of filteredTypes" (click)="selectType(type)">
                    <span class="withDate">{{type.description}}
                        <span class="text-sm">{{" [" +
                            extractYear(type.beginYearMonth) + (type?.endYearMonth ? "-" : '') + extractYear(type.endYearMonth) +
                            "]"}}</span>
                    </span><i class="text-gray-700 fa-solid fa-angle-right ml-1"></i>
                </li>
            </ul>
        </div>
        <button class="search-button" (click)="onSearch()">
            <i class="fa fa-search"></i>
        </button>
        <button class="search-button !bg-red-500" (click)="clear()">
            <i class="fa fa-times"></i>
        </button>
    </div>
</div>